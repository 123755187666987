<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>{{ $t("companysetting.field.table.create.title") }}</span>
      </v-card-title>
      <v-form
        :lazy-validation="true"
        @submit.prevent="handleSubmit"
        v-model="valid"
      >
        <v-card-text>
          <v-autocomplete
            v-model="selectedHeaderKey"
            :items="selectableHeaders"
            :label="$t('companysetting.field.table.create.placeholder')"
            item-value="key"
            item-text="title"
            @change="updateSelected"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.title"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.subgroup"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-text-field
            v-if="selectedHeader && !selectedHeader.isDefault"
            v-model="label"
            :label="$t('companysetting.field.table.create.columntitle')"
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" type="submit" x-large>{{
            $t("common.add")
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getDefaultNodeTableHeaders } from "../../../_helpers/tagHelper";
export default {
  name: "NodeTableEntryCreate",
  data() {
    return {
      dialog: false,
      label: "",
      valid: false,
      selectedHeaderKey: null,
      selectedHeader: null,
      allSelectableHeaders: [{ header: this.$t("common.default") }],
      selectableHeaders: [],
    };
  },

  props: {
    currentHeaders: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState("tag", ["tagsAttributes"]),
  },

  methods: {
    ...mapActions("tag", ["getAllTagAttributes"]),

    async handleSubmit() {
      if (this.selectedHeader !== null) {
        if (!this.selectedHeader.isDefault && this.label !== "") {
          this.selectedHeader.title = this.label;
        }
        this.$emit("added-entry", this.selectedHeader);
        this.reset();
      }
    },

    reset() {
      this.dialog = false;
      this.selectedHeader = null;
      this.selectedHeaderKey = null;
      this.label = "";
    },

    updateSelected() {
      this.selectedHeader = this.selectableHeaders.find(
        (x) => x.key === this.selectedHeaderKey
      );
    },

    async fetchTagAttributes() {
      await this.getAllTagAttributes();
    },
  },

  created() {
    //Default
    this.allSelectableHeaders.push(
      ...getDefaultNodeTableHeaders.map((x) => {
        return {
          index: -1,
          title: x.useLangLine ? this.$t(x.langLine) : x.title,
          key: x.key,
          subgroup: this.$t("common.default"),
          isDefault: true,
          isAttribute: false,
          useLangLine: x.useLangLine,
          langLine: x.useLangLine ? x.langLine : null,
          sortable: x.sortable ?? null,
        };
      })
    );

    //Attributes
    this.fetchTagAttributes();
    this.allSelectableHeaders.push(
      ...[{ divider: true }, { header: this.$t("tag.attributes.title") }]
    );
    this.allSelectableHeaders.push(
      ...this.tagsAttributes.map((x) => {
        return {
          index: -1,
          title: x.name,
          key: "attributeDict." + x.normalizedName,
          subgroup: this.$t("tag.attributes.titleSingular"),
          isDefault: false,
          isAttribute: true,
          useLangLine: false,
          langLine: null,
        };
      })
    );

    //Filters out already selected headers
    this.selectableHeaders = this.allSelectableHeaders.filter((x) => {
      return !this.currentHeaders.some((y) => x.key === y.key);
    });
  },

  watch: {
    currentHeaders: {
      handler(value) {
        //Filters out already selected headers
        this.selectableHeaders = this.allSelectableHeaders.filter((x) => {
          return !value.some((y) => x.key === y.key);
        });
      },
      deep: true,
    },
  },
};
</script>
