<template>
  <div>
    <p :class="'text-h5 text-left mt-4'">
      {{ $t("companysetting.field.table.title") }}
    </p>
    <p :class="'text-caption text-left'">
      {{ $t("companysetting.field.table.subtitle") }}
    </p>
    <draggable
      class="slot-indexing-container d-flex justify-start align-center flex-wrap"
      v-model="localHeaders"
      @change="changed"
      draggable=".node-header-movable-class"
    >
      <div
        v-for="header in localHeaders"
        :key="header.key"
        class="node-header-movable-class"
      >
        <v-card :class="'elevation-2 rounded-lg border ma-2'" hover>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            {{ header.title }}
            <v-spacer></v-spacer>
            <v-btn small icon @click="removeHeader(header.index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
        </v-card>
      </div>
      <node-table-entry-create
        v-if="localHeaders.length > 0"
        :currentHeaders="localHeaders"
        v-on:added-entry="addHeader"
        slot="footer"
      />
    </draggable>
    <div v-if="localHeaders.length == 0" class="pa-2">
      <node-table-entry-create
        :currentHeaders="localHeaders"
        v-on:added-entry="addHeader"
      />
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import NodeTableEntryCreate from "./NodeTableEntryCreate.vue";
import { getDefaultNodeTableHeaders } from "../../../_helpers/tagHelper";

export default {
  name: "NodeTableColumnsForm",

  components: {
    draggable,
    NodeTableEntryCreate,
  },

  data() {
    return {
      localHeaders: [],
    };
  },

  props: {
    nodeHeaders: {
      type: String,
      default: () => "",
    },
  },

  methods: {
    changed(emit = true) {
      for (var i = 0; i < this.localHeaders.length ?? 0; i++) {
        this.localHeaders[i].index = i;
      }
      if (emit) this.$emit("update", JSON.stringify(this.localHeaders));
    },

    removeHeader(idx) {
      this.localHeaders.splice(idx, 1);
      this.changed();
    },

    addHeader(payload) {
      this.localHeaders.push(payload);
      this.changed();
    },
  },

  async created() {
    this.localHeaders = JSON.parse(this.nodeHeaders);

    if (this.localHeaders.length === 0) {
      this.localHeaders = getDefaultNodeTableHeaders.map((x) => {
        return {
          index: -1,
          title: x.useLangLine ? this.$t(x.langLine) : x.title,
          key: x.key,
          subgroup: this.$t("common.default"),
          isDefault: true,
          isAttribute: false,
          useLangLine: x.useLangLine,
          langLine: x.useLangLine ? x.langLine : null,
          sortable: x.sortable ?? null,
        };
      });
    }
    this.changed(false);
  },

  watch: {
    nodeHeaders: {
      handler(value) {
        this.localHeaders = JSON.parse(value);
      },
      deep: true,
    },
  },
};
</script>
