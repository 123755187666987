<template>
  <v-tabs>
    <v-tab v-for="(item, idx) in companyFields" :key="'tab-title-' + idx">{{
      $t(item.label)
    }}</v-tab>

    <v-tab v-if="canAccessFeature(FEATURES.PER_COMPANY_BRANDING)">{{
      $t("settings.company.branding")
    }}</v-tab>

    <v-tab-item v-for="(item, ix) in companyFields" :key="'tab-content-' + ix">
      <v-progress-circular
        v-if="loading"
        :size="80"
        :width="8"
        color="accent"
        indeterminate
      />
      <company-setting-field v-else :field="item" :parent="{}" />

      <v-btn
        :disabled="loading"
        type="btn"
        @click="handleSubmit"
        color="primary"
        >{{ $t("common.save") }}
      </v-btn>
    </v-tab-item>

    <v-tab-item v-if="canAccessFeature(FEATURES.PER_COMPANY_BRANDING)">
      <v-container style="margin: 0; padding: 0; width: 100%; max-width: 100%">
        <company-branding-form />
      </v-container>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import CompanySettingField from "./CompanySettingField.vue";
import CompanyBrandingForm from "./CompanyBrandingForm.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "CompanySettings",

  data() {
    return {
      loading: false,
    };
  },

  async created() {
    await this.getCompanySettings();
  },

  methods: {
    ...mapActions("configuration", [
      "getCompanySettings",
      "setCompanySettings",
    ]),

    updateData(value) {
      this.settings = value;
    },

    async handleSubmit() {
      this.loading = true;
      await this.setCompanySettings(this.companySettings);

      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },

  computed: {
    ...mapState("configuration", ["companySettings", "companyFields"]),
    ...mapState("users", ["currentUser"]),
  },

  components: {
    "company-setting-field": CompanySettingField,
    CompanyBrandingForm,
  },

  async beforeDestroy() {
    await this.getCompanySettings();
  },
};
</script>
