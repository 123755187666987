<template>
  <div style="width: 100%; max-width: 100%">
    <div
      style="width: 100%; max-width: 100% !important"
      v-if="field.conditionValue == null"
    >
      <p :class="'text-h5 text-left mt-4'" v-if="field.isTitle">
        {{ $t(field.label) }}
      </p>
      <v-form :lazy-validation="false" v-model="valid" ref="form">
        <v-select
          v-if="field.fieldType == Types.SELECT"
          :label="$t(field.label)"
          v-model="currentValue"
          :items="field.options"
          v-on:change="setValue"
          :name="field.label"
          :hint="$t(field.hint)"
          persistent-hint
        ></v-select>

        <v-text-field
          v-if="field.fieldType == Types.STRING"
          :label="$t(field.label)"
          v-model="currentValue"
          :name="field.label"
          v-on:change="setValue"
          :hint="$t(field.hint)"
          persistent-hint
        />

        <v-text-field
          v-if="field.fieldType == Types.PASSWORD"
          :label="$t(field.label)"
          v-model="currentValue"
          type="password"
          autocomplete="false"
          :name="field.label"
          v-on:change="setValue"
          :hint="$t(field.hint)"
          persistent-hint
        />

        <v-switch
          v-if="field.fieldType == Types.CHECKBOX"
          v-model="currentValue"
          :label="$t(field.label)"
          v-on:change="setValue"
          :hint="$t(field.hint)"
          persistent-hint
        />

        <v-menu
          ref="menu"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="currentValue"
          transition="scale-transition"
          offset-y
          min-width="auto"
          v-if="field.fieldType == Types.DATE"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="currentValue"
              :label="field.label"
              v-on:change="setValue"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :hint="$t(field.hint)"
              persistent-hint
            ></v-text-field>
          </template>
          <v-date-picker v-model="currentValue" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false">
              {{ $t("common.close") }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              {{ $t("common.save") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
        <node-table-columns-form
          v-if="field.fieldType === Types.NODE_TABLE_COLUMNS"
          :nodeHeaders="currentValue"
          @update="updateValue"
        />
        <coordinate-picker
          v-if="field.fieldType == Types.COORDINATE"
          :item="coordinatepickerInput"
          @update="updateCoordinates"
        />
      </v-form>
    </div>

    <v-container
      v-if="
        field.children && field.children.length > 0 && checkConditionalValue()
      "
      style="margin: 0; padding: 0; width: 100%; max-width: 100%"
    >
      <company-setting-field
        v-for="(f, i) in field.children"
        :key="i"
        :field="f"
        :parent="field"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CsharpEnum from "@/_helpers/CsharpEnum";
import NodeTableColumnsForm from "./nodeSettings/NodeTableColumnsForm.vue";
import CoordinatePicker from "../common/CoordinatePicker.vue";

export default {
  name: "company-setting-field",

  props: {
    field: {
      default: {},
    },

    parent: {
      default: {},
    },
  },

  components: {
    NodeTableColumnsForm,
    CoordinatePicker,
  },

  computed: {
    ...mapState("configuration", ["companySettings"]),

    Types() {
      return CsharpEnum.CompanySettingFieldType;
    },

    coordinatepickerInput() {
      let item = JSON.parse(this.currentValue);
      item.name = this.$t(this.field.label);
      return item;
    },
  },

  methods: {
    ...mapActions("configuration", ["setCompanySettingLocal"]),

    checkConditionalValue() {
      if (this.field.conditionValue == null) return true;

      // Take parent key to get the value from the settings in the store
      // and compare the value to the conditionValue if the value matches we
      // continue else we will "return" and not iterate the children
      var val = this.companySettings[this.parent.key];

      return val == this.field.conditionValue;
    },

    setValue() {
      if (!this.$refs.form.validate()) return;

      this.setCompanySettingLocal({
        key: this.field.key,
        value: this.currentValue,
      });
    },

    updateValue(payload) {
      this.currentValue = payload;
      this.setValue();
    },

    updateCoordinates(payload) {
      this.currentValue = JSON.stringify(payload);
      this.setValue();
    },
  },

  data() {
    return {
      currentValue: "",
      dateMenu: false,
      valid: false,
    };
  },

  created() {
    this.currentValue = this.companySettings[this.field.key];
  },
};
</script>
